<script setup lang="ts">
  const image_ids = Array.from({ length: 10 }, (_, i) => i );
  const h_l_images = image_ids.map(i => `/home/${i + 1}_h_1500.jpg`);
  const v_l_images = image_ids.map(i => `/home/${i + 1}_v_1500.jpg`);
  const h_srcset = image_ids.map(i => `/home/${i + 1}_h_1500.jpg 1500w, /home/${i + 1}_h_1000.jpg 1000w, /home/${i + 1}_h_400.jpg 400w`);
  const v_srcset = image_ids.map(i => `/home/${i + 1}_v_1500.jpg 844w, /home/${i + 1}_v_1000.jpg 562w, /home/${i + 1}_v_400.jpg 225w`);

  const isLandscape = ref(false);

  function onResize() {
    isLandscape.value = window.innerWidth > window.innerHeight;
  }

  onMounted(() => {
    onResize();
  });
</script>

<template>
  <v-sheet height="100vh" color="background" v-resize="onResize" class="mb-3">
    <v-carousel class="h-100 w-100" hide-delimiters>
      <template v-for="index in image_ids" :key="index">
        <v-carousel-item v-if="isLandscape" :src="h_l_images[index]" :srcset="h_srcset[index]" aspect-ratio="16/9" cover />
        <v-carousel-item v-else :src="v_l_images[index]" :srcset="v_srcset[index]" aspect-ratio="9/16" cover />
      </template>
    </v-carousel>
  </v-sheet>
</template>

<style scoped>
  :deep(.v-window__controls) > .v-btn {
    /* --v-btn-height: 6px; */
    /* background: rgba(var(--v-theme-surface), 0.5); */
    background: rgba(200, 200, 200, 0.1);
  }
</style>